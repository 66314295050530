@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&family=Viga&display=swap');

* {
  font-family: "Hanken Grotesk", sans-serif;
  transition: margin-top 0.5s, opacity 0.5s;
}

body {
  background-color: rgb(11, 11, 50);
}

.App {
  margin: 0;
  max-height: 140vh;
  max-width: 100vw;
  background-color: rgb(11, 11, 50);
  background-size: cover;
}

.Main {
  width: 80vw;
  height: fit-content;
  margin-left: 10vw;
  padding-top: 30vh;
}

.Header {
  width: 88vw;
  left: 6vw;
  margin-top: 2vh;
  height: 10vh;
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.Header-Title {
  color: whitesmoke;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 5vh;
}

.Header-Buttons {
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
  display: flex;
  gap: 2vw;
}

.Header-Button {
  width: 14vw;
  height: 7vh;
  padding: 1.75vh;
  padding-left: 1vw;
  padding-right: 1vw;
  border: none;
  background: none;
  color: whitesmoke;
  font-size: 2.5vh;
  letter-spacing: 1.5px;
  cursor: pointer;
  border-radius: 5px;
  transition: 1s;
}

.Header-Coin-Address {
  background-color: #8BC6EC;
  background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
}

.Header-Button:hover {
  letter-spacing: 2px;
}

.Main-Title-Div {
  width: 70vw;
  height: 50vh;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  /* background-color: #9599E2; */
  display: flex;
  flex-direction: column;
}

.Main-Title {
  font-size: 10vh;
  text-align: center;
  color: whitesmoke;
  font-weight: 800;
  width: 70vw;
}

.Coin-Address {
  margin-top: 15vh;
  padding: 3vh;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
  font-size: 4vh;
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: 800;
  border-radius: 15px;
  background-color: #8BC6EC;
  background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
}

.Features {
  width: 100%;
  height: fit-content;
  padding-bottom: 30vh;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
}

.Features-Title {
  color: whitesmoke;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 6vh;
  width: 100%;
  text-align: center;
}

.Features-Container {
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  height: 100vh;
  width: 80%;
  margin-left: 10%;
  margin-top: 15vh;
}

.Features-Grid-A {
  display: grid;
  grid-template-rows: 1.5fr 1.8fr 0.8fr;
}

.Features-Grid-B {
  display: grid;
  grid-template-rows: 0.9fr 1fr 0.8fr;
}

.Feature {
  height: 100%;
  width: 100%;
  background-color: #9599E2;
}

::-webkit-scrollbar {
  width: 1px;
  background-color: #9599E2;
}

.Breathe {
  -webkit-animation: color_change 3s infinite alternate;
  -moz-animation: color_change 3s infinite alternate;
  -ms-animation: color_change 3s infinite alternate;
  -o-animation: color_change 3s infinite alternate;
  animation: color_change 3s infinite alternate;
 }
 
 @-webkit-keyframes color_change {
  from { color: whitesmoke; letter-spacing: 1px; }
  to { color: lightblue; letter-spacing: 1.5px; }
 }
 @-moz-keyframes color_change {
  from { color: whitesmoke; letter-spacing: 1px; }
  to { color: lightblue; letter-spacing: 1.5px; }
 }
 @-ms-keyframes color_change {
  from { color: whitesmoke; letter-spacing: 1px; }
  to { color: lightblue; letter-spacing: 1.5px; }
 }
 @-o-keyframes color_change {
  from { color: whitesmoke; letter-spacing: 1px; }
  to { color: lightblue; letter-spacing: 1.5px; }
 }
 @keyframes color_change {
  from { color: whitesmoke; letter-spacing: 1px; }
  to { color: lightblue; letter-spacing: 1.5px; }
 }